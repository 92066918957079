<template>
  <div style="background-image: linear-gradient(#f3f2f5, #fff)">
    <header class="s-header-text">
      <div class="s-header-heading" data-effect="heading">
        <h3 class="f-st" style="opacity: 1;"></h3>
        <h2 class="f-hl" style>
          <div
            class="f-hl-line"
            style="display: block; text-align: start; position: relative;"
          >Book an Appointment</div>
        </h2>
      </div>
      <button class="s-header-scroll">
        Scroll
        <svg viewBox="0 0 6 10">
          <path
            d="M0,9.9C1.4,8.3,2.9,5.6,3.4,5C2.9,4.4,1.4,1.7,0,0.1L0.1,0C1.5,1.6,5.8,4.8,6,5 c-0.2,0.2-4.5,3.4-5.9,5L0,9.9z"
          />
        </svg>
        <svg viewBox="0 0 6 10">
          <path
            d="M0,9.9C1.4,8.3,2.9,5.6,3.4,5C2.9,4.4,1.4,1.7,0,0.1L0.1,0C1.5,1.6,5.8,4.8,6,5 c-0.2,0.2-4.5,3.4-5.9,5L0,9.9z"
          />
        </svg>
      </button>
    </header>
    <form v-on:submit="bookAppoinment()" style="background-image: linear-gradient(#f3f2f5, #fff)" class="section-appointment">
      <div class="left">
        <p id="showmessage">Your Appointment has been booked Successfully.</p>
        <p id="showerror">Please Select Date and time of Appointment</p>
        <div class="f-contact">
          <p class="f-prr">Contact With Our Fashion Expert</p>
          <input type="text" class="f-contact-m" placeholder="Name" v-model="obj.name" required/>
          <input type="email" class="f-contact-m" placeholder="Email" v-model="obj.email" required/>
          <input
            type="number"
            class="f-contact-l"
            placeholder="Phone No"
            v-model="obj.phone_no" required
          />
          <textarea class="f-contact-t-a" placeholder="Message" v-model="obj.message" required/>
          <button class="f-contact-btn" type="submit">
            <p class="f-prr">Send Message</p>
          </button>
        </div>
      </div>
      <div class="right">
          <div class="f-contact">
            <p class="f-prr">Please Select Date and time of Appointment</p>
            <vc-date-picker v-model="obj.appdate" mode="dateTime" 
            :disabled-dates='{ weekdays: [1]}' :min-date='new Date()'
            :timezone="timezone" required/>
          </div>
      </div>
    </form>
  </div>
</template>

<script>
import SingleDatePicker from "vue-single-date-picker";
import axios from 'axios'
import moment from 'moment'
export default {
  props: ["items"],
  components: {
    SingleDatePicker
  },
  data() {
    return {
      obj: {},
      name: '',
      email: '',
      phone_no: '',
      message: '',
      date: new Date(),
      tomorrow: moment().add(1,'days').format('DD-MM-YYYY hh:mm A'),
      formattedDate: false,
      timezone: 'utc'
    };
  },
  mounted() {
  },
  methods: {
    dateFormat(d) {
      
      // console.log(moment(new Date()).format('DD-MM-YYYY hh:mm A'))
      if (d) {

        this.formattedDate = moment(d).format("DD-MM-YYYY");
        return this.formattedDate
      }
    },
    bookAppoinment(e){
      event.preventDefault();
      console.log(this.obj.appdate, 'date, tme')
      if(this.obj.appdate){
        // console.log(moment(new Date()).format('DD-MM-YYYY hh:mm A'), 'current date')
          this.obj.event = this.$store.state.event
          this.obj.createdAt = moment()
          if(this.obj.appdate){
            this.obj.appointment_date = moment(this.obj.appdate).format("DD-MM-YYYY");
            this.obj.appointment_time = moment(this.obj.appdate).format("hh:mm A");
          }
          axios
            .post(
                this.$store.state.api + "appointment/_doc",
                this.obj
            ).then(response => {
                x = document.getElementById("showmessage");
                x.className = "active";
                // console.log(response, 'response app')
              axios.post(this.$store.state.api + "send_email", {
                    user: {
                        email: this.obj.email
                    },
                    subject: "Meethas Order Confirmation",
                    object: {
                        name: this.obj.name,
                        appointment_time: this.obj.appointment_time,
                        appointment_date: this.obj.appointment_date,
                    },
                    bcc: [
                        "arwashahid052@gmail.com",
                    ],
                    from: "info@virtualeye.online",
                    template_id: "d-527103bbef5943ed832d8ac5bc421ea0"
                });
                setTimeout(function(){ 
                    x.className = x.className.replace("showmessage", ""); 
                    x.className = "";
                }, 3000);
                this.obj = {}
            });
      }else{
        var x = document.getElementById("showerror");
        x.className = "active";
        setTimeout(function(){ 
          x.className = x.className.replace("showerror", ""); 
          x.className = "";
        }, 3000);
      }
    }
  }
};
</script>

<style scoped>
.section-appointment {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}
.left {
  width: 55%;
  min-width: 55%;
  padding: 5%;
}
.right {
  width: 40%;
  min-width: 40%;
  padding: 2%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
}
.s-banner h3 {
  display: block;
  text-align: start;
  position: relative;
  transform-origin: 0px 0px;
  transform: translate3d(0px, 0px, 0px);
  opacity: 1;
  opacity: 0.8;
  font-weight: bold;
  font-size: 0.688vw;
  line-height: 0.688vw;
  margin-bottom: 3.75vw;
  letter-spacing: 0.188vw;
  text-transform: uppercase;
  font-family: "Aktiv Grotesk";
}
.s-banner h2 {
  font-weight: 300;
  font-size: 4.188vw;
  line-height: 4.5vw;
  margin-bottom: 3vw;
  letter-spacing: -0.179vw;
  font-family: "Saol Display";
}
.s-banner p {
  opacity: 0.8;
  font-size: 0.938vw;
  font-weight: normal;
  line-height: 1.875vw;
  letter-spacing: 0.034vw;
  font-family: "Aktiv Grotesk";
}
.f-nr {
  color: #ae97a2;
  font-weight: 300;
  font-size: 12.5vw;
  line-height: 9.688vw;
  letter-spacing: -0.665vw;
  font-family: "Saol Display";
}
.f-hs {
  font-weight: 300;
  font-size: 2.688vw;
  line-height: 2.896vw;
  letter-spacing: -0.104vw;
  font-family: "Saol Display";
}
.f-pr {
  opacity: 0.8;
  font-size: 0.938vw;
  font-weight: normal;
  line-height: 1.875vw;
  letter-spacing: 0.034vw;
  font-family: "Aktiv Grotesk";
  text-align: justify;
}
.s-values .f-pr,
.s-banner-text p {
  width: calc(100vw / 15 * 3);
}
.s-values {
  display: flex;
  flex-wrap: wrap;
  margin-left: calc(100vw / 20 * 2);
  margin-right: calc(100vw / 20 * 2);
  padding-bottom: 16.25vw;
  margin-bottom: 0;
}

.s-header-text {
  display: flex;
  height: 35vh !important;
  align-items: flex-end;
  margin-bottom: 50px;
}
.s-header-scroll {
  bottom: 1vw;
}
.f-contact {
  width: 100%;
  padding: 1%;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-wrap: wrap;
}
.f-contact .f-contact-m {
  height: 4vw;
  color: #1f1f1f;
  font-size: 0.938vw;
  font-weight: normal;
  font-family: "Aktiv Grotesk";
  outline: 0;
  -moz-appearance: none;
  appearance: none;
  border-radius: 0;
  padding: 0 1.25vw;
  line-height: 3.563vw;
  letter-spacing: 0.034vw;
  background: transparent;
  -webkit-appearance: none;
  border: 1px solid #d2d2d2;
  width: 48%;
  margin: 1%;
}
.f-contact .f-contact-l {
  height: 4vw;
  color: #1f1f1f;
  font-size: 0.938vw;
  font-weight: normal;
  font-family: "Aktiv Grotesk";
  outline: 0;
  -moz-appearance: none;
  appearance: none;
  border-radius: 0;
  padding: 0 1.25vw;
  line-height: 3.563vw;
  letter-spacing: 0.034vw;
  background: transparent;
  -webkit-appearance: none;
  border: 1px solid #d2d2d2;
  width: 99%;
  margin: 1%;
}
.f-contact .f-contact-t-a {
  height: 8vw;
  color: #1f1f1f;
  font-size: 0.938vw;
  font-weight: normal;
  font-family: "Aktiv Grotesk";
  outline: 0;
  -moz-appearance: none;
  appearance: none;
  border-radius: 0;
  padding: 0 1.25vw;
  line-height: 3.563vw;
  letter-spacing: 0.034vw;
  background: transparent;
  -webkit-appearance: none;
  border: 1px solid #d2d2d2;
  width: 99%;
  margin: 1%;
}
.f-contact .f-contact-btn {
  width: 98%;
  margin: 1% 1%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #232323 !important;
  color: #fff;
  padding: 4% 0;
}
.f-contact .f-prr {
  width: 100%;
  margin: 4% 1%;
  font-family: "Saol Display";
}
.f-contact .f-contact-btn .f-prr {
  margin: 0;
  font-family: "Aktiv Grotesk";
}
.f-contact .f-contact-m {
  height: 4vw;
  color: #1f1f1f;
  font-size: 0.938vw;
  font-weight: normal;
  font-family: "Aktiv Grotesk";
  outline: 0;
  -moz-appearance: none;
  appearance: none;
  border-radius: 0;
  padding: 0 1.25vw;
  line-height: 3.563vw;
  letter-spacing: 0.034vw;
  background: transparent;
  -webkit-appearance: none;
  border: 1px solid #d2d2d2;
}

@media only screen and (max-width: 580px) {
  .s-banner p {
    margin-left: calc(100vw / 12 * 1);
  }
  .s-values .f-pr,
  .s-banner-text p {
    width: calc(100vw / 12 * 8);
    font-size: 4.167vw;
    line-height: 7.5vw;
    letter-spacing: 0.15vw;
  }
  .s-banner h2 {
    font-size: 11.111vw;
    line-height: 11.944vw;
    margin-bottom: 8.333vw;
    letter-spacing: -0.417vw;
  }
  .s-banner h3 {
    font-size: 2.778vw;
    line-height: 4.167vw;
    margin-bottom: 5.556vw;
    letter-spacing: 0.75vw;
  }
  section,
  figure {
    margin: 13vw 0;
  }
  .s-values {
    display: block;
    flex-wrap: unset;
    margin-left: calc(100vw / 12 * 1);
    margin-right: calc(100vw / 12 * 1);
    padding-bottom: 16.25vw;
    margin-bottom: 0;
  }
  .s-values-block {
    width: auto;
    padding: 13.889vw 0;
    border-top: 1px solid #e8d6ca;
  }
  .s-values .f-nr {
    margin: 22.222vw 0 13.889vw;
    font-size: 55.556vw;
    line-height: 41.556vw;
    letter-spacing: -2.956vw;
  }
  .s-values .f-hs {
    margin-bottom: 6.944vw;
    font-size: 11.111vw;
    line-height: 11.944vw;
    letter-spacing: -0.417vw;
  }
  .s-values .f-pr {
    width: calc(100vw / 12 * 8);
    font-size: 4vw;
    line-height: 6.5vw;
    letter-spacing: 0.15vw;
    text-align: left;
  }
  .section-appointment {
      flex-wrap: wrap-reverse;
      align-items: center;
      justify-content: center;
  }
  .left {
    width: 90%;
    padding: 5%;
    justify-content: center;
    align-items: center;
  }
  .f-contact{
    justify-content: center;
    align-items: center;
  }
  .f-contact .f-prr{
      text-align: center;
  }
  .right {
    width: 90%;
    padding: 5%;
    justify-content: center;
    align-items: center;
  }
  .f-contact .f-contact-m{
      height: 7vw;
      width: 100%;
      font-size: 12px;
  }
  .f-contact .f-contact-l{
      height: 7vw;
      width: 100%;
      font-size: 12px;
  }
  .f-contact .f-contact-t-a{
      height: 15vw;
      width: 100%;
      font-size: 12px;
      padding: 2% 2%;
  }
}
#showmessage{
  background: #d2e4c4;
  color: #307a07;
  padding: 10px 20px;
  font-size: 14px;
  display: none;
  margin-top: 10px;
}
#showerror{
  background: rgba(233,30,99,.05);
  color: #e91e63;
  padding: 10px 20px;
  font-size: 14px;
  display: none;
  margin-top: 10px;
}
.active {
   /* transform: translateY(-100px) !important; */
   display: flex !important;
}
</style>
